// extracted by mini-css-extract-plugin
export var course = "talent-pool-embed-module--course--04b3d";
export var custom_toggle = "talent-pool-embed-module--custom_toggle--9e4a4";
export var cv_error = "talent-pool-embed-module--cv_error--20a0f";
export var date = "talent-pool-embed-module--date--9c120";
export var drop_select = "talent-pool-embed-module--drop_select--771c7";
export var event_banner = "talent-pool-embed-module--event_banner--a5789";
export var form_control = "talent-pool-embed-module--form_control--410a8";
export var initial = "talent-pool-embed-module--initial--c9de4";
export var initial_intro_title = "talent-pool-embed-module--initial_intro_title--fa983";
export var initial_tc = "talent-pool-embed-module--initial_tc--41a85";
export var invalid_feedback = "talent-pool-embed-module--invalid_feedback--a09f5";
export var invalid_feedback_input = "talent-pool-embed-module--invalid_feedback_input--4c1fe";
export var mfSub = "talent-pool-embed-module--mfSub--e72cf";
export var processing = "talent-pool-embed-module--processing--ccdb3";
export var processingBtn = "talent-pool-embed-module--processingBtn--c2fc5";
export var processing_intro_title = "talent-pool-embed-module--processing_intro_title--d4653";
export var processing_tc = "talent-pool-embed-module--processing_tc--08953";
export var radio_btn = "talent-pool-embed-module--radio_btn--f1839";
export var send_icon = "talent-pool-embed-module--send_icon--07a6b";
export var success = "talent-pool-embed-module--success--9dd46";
export var successBtn = "talent-pool-embed-module--successBtn--b4112";
export var success_intro_title = "talent-pool-embed-module--success_intro_title--9677d";
export var success_message = "talent-pool-embed-module--success_message--b8970";
export var success_message_init = "talent-pool-embed-module--success_message_init--40b00";
export var ts_cs = "talent-pool-embed-module--ts_cs--9ff2b";