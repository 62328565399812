import React, { useEffect, useState } from "react"
import { Link } from "gatsby"
import { useLocation } from "@reach/router"
import TalentPoolEmbed from "./talent-pool-embed"
import * as s from "./open-role-missing.module.css"

const OpenRoleFourZeroFour = () => {
  const [isOpenRole, setIsOpenRole] = useState(0)

  var urlPathname = useLocation()
  var url = urlPathname.pathname || null

  useEffect(() => {
    url = urlPathname.pathname || null
    if (url.includes("open-roles")) {
      setIsOpenRole(1)
    } else {
      setIsOpenRole(2)
    }
  })

  const featuredImgUrl =
    "url(https://res.cloudinary.com/dvmdv4ttu/image/upload/f_auto/img/careers-404-hero_vtvfbo.jpg)"

  return (
    <>
      <div
        id="altFourOhFour"
        className="d-flex align-items-center bg_image flex-column justify-items-center mb-0"
        style={{
          backgroundImage: featuredImgUrl,
          height: "100vh",
          minHeight: "600px",
        }}
      >
        <div className="container flex-grow-1">
          <div
            id="hide-row-when-nav-open"
            className="row h-100 align-content-center"
          >
            <span>
              <div className="col-12 color-white animated-headline z_index_top">
                {isOpenRole === 0 && (
                  <>
                    <div
                      className={`animated-headline-wrap`}
                      style={{ visibility: "hidden" }}
                    >
                      <h1 className="new_style">Oops!</h1>
                    </div>
                    <span
                      className="mt-4 pb-4 d-block header-fade-in"
                      style={{ fontSize: "1.3rem", visibility: "hidden" }}
                    >
                      Place <br className="d-none d-lg-block" />
                      Holder
                    </span>
                  </>
                )}
                {isOpenRole === 2 && (
                  <>
                    <div className={`animated-headline-wrap`}>
                      <h1 className="new_style">Oops!</h1>
                    </div>
                    <span
                      className="mt-4 pb-4 d-block header-fade-in"
                      style={{ fontSize: "1.3rem" }}
                    >
                      Apologies, this page is no longer available.{" "}
                      <br className="d-none d-lg-block" />
                      But, your journey doesn't have to end here with Waracle.
                    </span>
                  </>
                )}
                {isOpenRole === 1 && (
                  <>
                    <div className={`animated-headline-wrap`}>
                      <h1 className="new_style">
                        Sorry, that Job Vacancy has closed!
                      </h1>
                    </div>
                    <span
                      className="mt-4 pb-4 d-block header-fade-in"
                      style={{ fontSize: "1.3rem" }}
                    >
                      However, we love hearing from people who want to work for
                      us. <br className="d-none d-lg-block" />
                      So why not join our talent pool by using the form below?
                    </span>
                  </>
                )}
              </div>
              {isOpenRole === 0 && (
                <div
                  className="col-12 mt-4 z_index_top header-fade-in"
                  style={{ visibility: "hidden" }}
                >
                  <a
                    href="#contactForm"
                    title="Submit CV"
                    className={`${s.white_button} d-inline-block`}
                  >
                    Placeholder
                    <img
                      src="/assets/img/arrow-grey.svg"
                      alt="grey arrow icon"
                      className="ms-3"
                    />
                  </a>
                </div>
              )}
              {isOpenRole === 1 && (
                <div className="col-12 mt-4 z_index_top header-fade-in">
                  <a
                    href="#contactForm"
                    title="Submit CV"
                    className={`${s.white_button} d-inline-block ${s.rotate}`}
                  >
                    Submit Your CV Now!
                    <img
                      src="/assets/img/arrow-grey.svg"
                      alt="grey arrow icon"
                      className="ms-3"
                    />
                  </a>
                </div>
              )}
              {isOpenRole === 2 && (
                <div className="col-12 mt-4 z_index_top header-fade-in">
                  <Link
                    to="/"
                    title="Visit home page"
                    className={`${s.white_button} d-inline-block`}
                  >
                    Go to home page
                    <img
                      src="/assets/img/arrow-grey.svg"
                      alt="grey arrow icon"
                      className="ms-3"
                    />
                  </Link>
                </div>
              )}
            </span>
          </div>
        </div>
        <div className="dark_image_overly"></div>
      </div>
      <div
        className="darkgrey_bg color-white"
        style={{
          backgroundImage:
            " url(https://res.cloudinary.com/dvmdv4ttu/image/upload/v1679916214/img/careers-404-bg-dots_q7jxup.png)",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center 125%",
        }}
      >
        <div className="container pt-6 pb-6">
          <div className="row">
            <div className="col-12 mb-md-5 pb-4 pb-md-5 mt-1 mt-md-0 text-center">
              <h2 className="h3">Continue your journey with Waracle</h2>
            </div>
          </div>
          <div className="row justify-content-evenly">
            <div className="col-12 col-md-5 col-lg-4">
              <h3 className="h2">
                Discover open roles <br className="d-none d-lg-block" />
                at Waracle
              </h3>
              <p className="mt-3 mt-md-5">
                Are you a team player? Do you have discipline expertise and know
                how to deliver intelligent digital products for clients in
                Financial Services, Digital Health & Energy. Well, we're looking
                for you.
              </p>

              <Link
                to="/careers/open-roles/"
                title="Homepage link"
                className="ani_white_arrow color-white mt-5 d-inline-block"
              >
                Careers
              </Link>
            </div>

            <div className="col-12 col-md-5 col-lg-4 mt-4 mt-md-0">
              <h3 className="h2">
                Learn more about <br className="d-none d-lg-block" />
                Waracle's expertise
              </h3>
              <p className="mt-3 mt-md-5">
                We are a digital product agency that defines, designs and
                engineers some of the most innovative and intelligent digital
                products on the market. Find out more about who we work with and
                what we help them build!
              </p>
              <Link
                to="/"
                title="Homepage link"
                className="ani_white_arrow color-white mt-5 d-inline-block"
              >
                Home
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid grey_bg">
        <div className="row justify-content-center">
          <div
            id="contactForm"
            className="col-12"
            style={{
              backgroundImage:
                "url(https://res.cloudinary.com/dvmdv4ttu/image/upload/f_auto/img/careers-404-pre-form_fqyvuh.jpg)",
              backgroundSize: "cover",
              backgroundPosition: "center",
              minHeight: "500px",
            }}
          ></div>
          <div
            className="col-12 col-sm-10 col-md-8 col-xl-5"
            style={{ marginTop: "-250px" }}
          >
            {isOpenRole === 1 && (
              <TalentPoolEmbed recruiteeid="Speculative Applications" />
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default OpenRoleFourZeroFour
