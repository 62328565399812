import React, { useState, useCallback, useMemo } from "react"
import { Link } from "gatsby"
import ReCAPTCHA from "react-google-recaptcha"
import { useForm } from "react-hook-form"
import { useDropzone } from "react-dropzone"
import S3 from "react-aws-s3"
import { Buffer } from "buffer"
import * as s from "./talent-pool-embed.module.css"

const TalentPoolEmbed = props => {
  //AWS
  // required for file upload
  const isBrowser = () => typeof window !== "undefined"

  if (isBrowser) {
    window.Buffer = window.Buffer || require("buffer").Buffer
  }

  // s3 config
  const config = {
    bucketName: process.env.GATSBY_S3_BUCKET_NAME,
    dirName: process.env.GATSBY_S3_BUCKET_FOLDER,
    region: process.env.GATSBY_S3_BUCKET_REGION,
    accessKeyId: process.env.GATSBY_S3_BUCKET_KEY_ID,
    secretAccessKey: process.env.GATSBY_S3_BUCKET_SECRET_ACCESS_KEY,
  }

  const ReactS3Client = new S3(config)

  // set thee form up
  const theVacancyId = props.recruiteeid

  // dropzone styles

  const baseStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px",
    borderWidth: 2,
    borderRadius: 0,
    borderColor: "#202020",
    borderStyle: "solid",
    backgroundColor: "#202020",
    color: "#fff",
    transition: "border .3s ease-in-out",
  }

  const activeStyle = {
    borderColor: "#2196f3",
  }

  const acceptStyle = {
    borderColor: "#00e676",
  }

  const rejectStyle = {
    borderColor: "#ff1744",
  }

  // set validation classes
  const classInit = `form-control mt-5 ${s.form_control}`
  const classError = `${classInit} ${s.invalid_feedback_input}`

  // set form state
  const [submitStatus, setSubmitStatus] = useState("initial")
  const [status, setStatus] = useState("A CV is required")
  const [file, setFile] = useState({})
  const [errorMessage, setErrorMessage] = useState("")
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()

  const onDrop = useCallback(acceptedFiles => {
    setFile(acceptedFiles[0])

    if (acceptedFiles[0] !== undefined) {
      setStatus(acceptedFiles[0].name)
      setSubmitStatus("initial")
    }
  }, [])

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop,
    maxFiles: 1,
    maxSize: 1048576,
    accept: {
      "application/pdf": [".pdf", ".docx", ".doc"],
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        [".docx"],
      "application/msword": [".doc"],
    },
  })

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  )

  // end dropzone

  // set success function

  const subSuccess = () => {
    // set small delay to allow smoother animation
    setTimeout(function () {
      setSubmitStatus("success")
      //console.log("Received by Netlify")
    }, 1000)
  }

  const [verified, setVerified] = useState(false)
  const recaptchaRef = React.createRef()
  const [recap, setRecap] = useState("")

  const onChange = value => {
    setRecap(value)
    setVerified(prevVerified => !prevVerified)
  }

  // handle submission

  const onSubmit = data => {
    // Recruitee doesn't like non numerical data here.. simply strip out non-numbers
    let telNumberCleaned = data.enqTelephone.replace(/\D/g, "")

    if (file.path === undefined) {
      setSubmitStatus("initial")
    } else {
      setSubmitStatus("processing")
      setErrorMessage("")

      var formData = new FormData()

      formData.append("form-name", "recruitmentApplication")
      formData.append("enqPortfolio", data.enqPortfolio)
      formData.append("enqFirstName", data.enqFirstName)
      formData.append("enqSecondName", data.enqSecondName)
      formData.append("enqSalary", data.enqSalary)
      formData.append("enqEmail", data.enqEmail)
      formData.append("enqTelephone", telNumberCleaned)
      formData.append("enqMessage", data.enqMessage)
      formData.append("enqMIscData", data.enqMIscData)
      formData.append("enqRecruiteeId", theVacancyId)
      formData.append("g-recaptcha-response", recap)

      var pubUri
      ReactS3Client.uploadFile(file)
        .then(function (datos) {
          pubUri = datos.location
          console.log(pubUri)

          // add the cv url
          formData.append("enqCV", pubUri)

          console.log("Sending to Netlify")

          //send the data to netlify
          fetch("/", {
            method: "POST",
            body: formData,
          })
            .then(() => subSuccess())
            .catch(error => setSubmitStatus("initial"))
        })
        .catch(err => {
          setSubmitStatus("initial")
          if (err.status === 403) {
            setErrorMessage(
              "A file upload error with a status code 403 has occurred. If this problem persists, please contact us via the main contact form."
            )
          } else {
            setErrorMessage(
              "An unknown error has occurred with the CV file upload. If this problem persists, please contact us via the main contact form."
            )
          }
        })
    }
  }

  // generate form

  return (
    <>
      <div
        className="p-4 mb-5"
        style={{
          backgroundColor: "#141313",
          minHeight: "500px",
        }}
      >
        <div className="container gx-0">
          <div
            className="row justify-content-center"
            style={{ backgroundColor: "#141313" }}
          >
            <div className="col-12">
              <div className="d-flex justify-content-center pb-3">
                <img
                  className="move-in-left mw-100"
                  src="/assets/img/Waracle-Icon.svg"
                  alt="waracle"
                  style={{ width: "40px" }}
                />
              </div>

              <h2 className="h3 mb-0 mt-3 mt-2 mt-lg-0 mx-lg-5 text-center px-3 px-lg-5 color-white">
                Submit your details
              </h2>

              <p
                className="mt-3 mx-2 mx-xl-5 text-center px-0 px-xl-3 color-white"
                style={{ fontSize: "18px" }}
              >
                Send us your information so that we can{" "}
                <br className="d-none d-xl-block" />
                consider you for future roles.
              </p>

              <form
                onSubmit={handleSubmit(onSubmit)}
                name="recruitmentApplication"
                method="POST"
                data-netlify="true"
                netlify-honeypot="enqMIscData"
                data-netlify-recaptcha="true"
                action="/thank-you"
              >
                <div
                  className={
                    submitStatus === "success"
                      ? `${s.initial} ${s.success}`
                      : "" || submitStatus === "processing"
                      ? `${s.initial}  `
                      : "" || submitStatus === "initial"
                      ? s.initial
                      : s.initial
                  }
                >
                  <div className="container gx-0">
                    <div className="row">
                      <div className="col-12">
                        <span className="d-block mb-2 mt-4 color-white text-center">
                          Attach your C.V. *
                        </span>
                        <div {...getRootProps({ style })}>
                          <input {...getInputProps()} name="file" />
                          <img
                            src="/assets/img/paper-clip-orange.png"
                            alt="atttachment icon"
                            style={{
                              maxWidth: "30px",
                              marginBottom: "10px",
                              marginTop: "5px",
                            }}
                          />
                          {isDragActive ? (
                            <div style={{ fontSize: "16px" }}>
                              Drop the files here...
                            </div>
                          ) : (
                            <div style={{ fontSize: "16px" }}>
                              Drop your file here, or browse
                            </div>
                          )}
                          {isDragReject ? (
                            <p
                              style={{
                                fontSize: "13px",
                                color: "#757575",
                                marginBottom: 0,
                              }}
                            >
                              File is not valid
                            </p>
                          ) : (
                            <p
                              style={{
                                fontSize: "13px",
                                color: "#757575",
                                marginBottom: 0,
                              }}
                            >
                              Supports PDF or word files less then 1mb
                            </p>
                          )}
                          <p style={{ fontSize: "13px" }}>{status}</p>
                        </div>
                        {status === "failed" && (
                          <span
                            className={`${s.invalid_feedback} d-block pt-1 form-text`}
                            style={{ position: "absolute" }}
                          >
                            A CV is required
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <input
                          {...register("enqPortfolio", { required: false })}
                          type="text"
                          placeholder="Portfolio link (optional)"
                          aria-label=""
                          className={`${classInit} ${s.form_control} mt-4`}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 col-lg-6">
                        <input
                          {...register("enqFirstName", { required: true })}
                          type="text"
                          placeholder="Your First Name *"
                          aria-label=""
                          className={
                            errors.enqFirstName
                              ? `${classError}`
                              : `${classInit}`
                          }
                        />
                        {errors.enqFirstName && (
                          <span
                            className={`${s.invalid_feedback} d-block pt-1 form-text`}
                            style={{ position: "absolute" }}
                          >
                            This field is required
                          </span>
                        )}
                      </div>
                      <div className="col-12 col-lg-6">
                        <input
                          {...register("enqSecondName", { required: true })}
                          type="text"
                          placeholder="Your Second Name *"
                          aria-label=""
                          className={
                            errors.enqSecondName
                              ? `${classError}`
                              : `${classInit}`
                          }
                        />
                        {errors.enqSecondName && (
                          <span
                            className={`${s.invalid_feedback} d-block pt-1 form-text`}
                            style={{ position: "absolute" }}
                          >
                            This field is required
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 col-lg-6">
                        <input
                          {...register("enqEmail", {
                            required: true,
                            pattern: /^\S+@\S+$/i,
                          })}
                          type="text"
                          placeholder="Email Address *"
                          aria-label=""
                          className={
                            errors.enqEmail ? `${classError}` : `${classInit}`
                          }
                        />
                        {errors.enqEmail && (
                          <span
                            className={`${s.invalid_feedback} d-block pt-1 form-text`}
                            style={{ position: "absolute" }}
                          >
                            Valid email address required
                          </span>
                        )}
                      </div>
                      <div className="col-12 col-lg-6">
                        <input
                          {...register("enqTelephone", { required: true })}
                          type="text"
                          placeholder="Telephone number *"
                          aria-label=""
                          className={
                            errors.enqTelephone
                              ? `${classError}`
                              : `${classInit}`
                          }
                        />
                        {errors.enqTelephone && (
                          <span
                            className={`${s.invalid_feedback} d-block pt-1 form-text`}
                            style={{ position: "absolute" }}
                          >
                            This field is required
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <select
                          {...register("enqSalary", { required: true })}
                          aria-label=""
                          className={
                            errors.enqSalary ? `${classError}` : `${classInit}`
                          }
                        >
                          <option value="">Salary Expectations *</option>
                          <option value="15-29k">£15 - 29k</option>
                          <option value="30-44k">£30 - 44k</option>
                          <option value="45-59k">£45 - 59k</option>
                          <option value="60k+">£60k +</option>
                        </select>
                        {errors.enqSalary && (
                          <span
                            className={`${s.invalid_feedback} d-block pt-1 form-text`}
                            style={{ position: "absolute" }}
                          >
                            This field is required
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <textarea
                          {...register("enqMessage")}
                          rows="4"
                          placeholder="Cover letter (optional)"
                          aria-label=""
                          className={
                            errors.enqMessage ? `${classError}` : `${classInit}`
                          }
                        />
                      </div>
                    </div>
                    <div className="row mt-5">
                      <div className="col-12 position-relative">
                        <ReCAPTCHA
                          ref={recaptchaRef}
                          sitekey={process.env.GATSBY_SITE_RECAPTCHA_KEY}
                          size="normal"
                          theme="dark"
                          onChange={onChange}
                        />
                        {!verified &&
                          (errors.enqFirstName ||
                            errors.enqSecondName ||
                            errors.enqTelephone ||
                            errors.enqEmail ||
                            errors.enqSalary ||
                            errors.enqMessage) && (
                            <span
                              className={`${s.invalid_feedback} position-absolute d-block pt-1 form-text`}
                              style={{
                                top: "75px",
                                left: "10px",
                              }}
                            >
                              Please confirm you are not a robot.
                            </span>
                          )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="d-none">
                  <input {...register("enqMIscData", { required: false })} />
                </div>

                {errorMessage !== "" && (
                  <div className="d-flex justify-content-center mt-5">
                    <div className={` ${s.cv_error}`}>
                      <img
                        className="close_button"
                        src="/assets/img/close-24-px-2.svg"
                        alt="error"
                      />{" "}
                      {errorMessage}
                    </div>
                  </div>
                )}

                <button
                  type="submit"
                  className={
                    submitStatus === "success"
                      ? `${s.mfSub} ${s.processingBtn}  ${s.successBtn}`
                      : "" || submitStatus === "processing"
                      ? `${s.mfSub} ${s.processingBtn}`
                      : "" || submitStatus === "initial"
                      ? `${s.mfSub} ${s.send_icon} mt-5`
                      : `${s.mfSub} ${s.send_icon} mt-5`
                  }
                >
                  {submitStatus === "success" ? (
                    <span>
                      <img
                        src="/assets/img/icon_success.svg"
                        alt=""
                        style={{ width: "32px" }}
                      />
                    </span>
                  ) : "" || submitStatus === "processing" ? (
                    <span>
                      <img
                        src="/assets/img/submit_white_alt.svg"
                        alt=""
                        style={{ width: "24px" }}
                      />
                    </span>
                  ) : "" || submitStatus === "initial" ? (
                    <span>
                      <img
                        src="/assets/img/submit_white_alt.svg"
                        alt=""
                        style={{ width: "24px", marginRight: "10px" }}
                      />{" "}
                      Send my request
                    </span>
                  ) : (
                    <span>
                      <img
                        src="/assets/img/submit_white_alt.svg"
                        alt=""
                        style={{ width: "24px", marginRight: "10px" }}
                      />{" "}
                      Send my request
                    </span>
                  )}
                </button>

                <div
                  className={
                    submitStatus === "success"
                      ? `${s.success_message_init} ${s.success_message} text-center`
                      : `${s.success_message_init}`
                  }
                >
                  <h3 className="text-center mt-4 ga_form_submit">
                    Application Successful
                  </h3>
                  <p className="text-center mb-4">
                    Thank you for your application.
                  </p>
                </div>

                <div
                  className={
                    submitStatus === "success"
                      ? `${s.ts_cs} ${s.initial_tc} ${s.success} mt-4`
                      : "" || submitStatus === "processing"
                      ? `${s.ts_cs} ${s.initial_tc}  mt-4`
                      : "" || submitStatus === "initial"
                      ? `${s.ts_cs} ${s.initial_tc} mt-4`
                      : `${s.ts_cs} ${s.initial_tc} mt-4`
                  }
                >
                  By submitting this form you agree to the storing and
                  processing of your personal data by Waracle as described in
                  the Project enquiry section of the{" "}
                  <Link to="/privacy-policy/" target="_blank">
                    {" "}
                    Privacy policy
                  </Link>
                  .
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default TalentPoolEmbed
