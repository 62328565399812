import React from "react"
import Layout from "../templates/Layout"
import OpenRoleFourZeroFour from "../components/careers/open-role-missing"
import { Helmet } from "react-helmet"

const PageTemplateNotFound = () => {
  return (
    <>
      <Helmet>
        <title>page not found | waracle</title>
        <script>{`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-W2TZ97');`}</script>
        <meta name="description" content="404 page not found" />
        <meta name="og:title" content="page not found | waracle" />
        <meta name="og:description" content="404 page not found" />
        <meta property="og:url" content={`https://waracle.com$/404`} />
        <meta property="og:locale" content="en_GB" />
        <meta property="og:type" content="website" />
        <meta name="robots" content="nofollow, noindex" />
      </Helmet>
      <Layout>
        <OpenRoleFourZeroFour />
      </Layout>
    </>
  )
}

export default PageTemplateNotFound
